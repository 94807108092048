/**
 * Outgoing call types to be shown in call window
 */
export enum OutgoingCallTypes {
	INTAKE = "intake",
	SESSION = "session",
	EMDR = "emdr",
	COLLEAGUE = "colleague",
	MDO = "mdo",
	NONE = "none",
}
