import {ContactTabPath} from "app/Path";
import {PresenceAvailability, UserRole, UserData} from "@sense-os/goalie-js";
import {SEMANTIC_COLORS} from "constants/SemanticEnums";
import {Optional} from "@sense-os/goalie-js/dist/helpers/types/utilityTypes";
import {getClientTabMenuRoute, getTherapistTabMenuRoute} from "../../helpers/routeGenerator";

/** First and last names of a person */
export interface PersonalName {
	firstName?: string;
	lastName?: string;
}

/**
 * A collection of user data utils
 * This collection should contain pure functions and no dependencies on DISC or VDISC!
 *
 * TODO: 1. Move all the pure methods from `UserUtils` here.
 * TODO: 2. Refactor the rest of the code accordingly.
 *
 *
 */
export namespace PureUserUtils {
	/**
	 * Takes first letter of each word in `fullName`, takes the letter's numeric unicode value,
	 * and concatenates all these numeric values into a number.
	 *
	 * Examples:
	 *
	 *  1. "Jack Smith" -> "J", "S" -> 74, 83; result: 7483
	 *  2. "Jan van der Laan" -> "J","v","d","L" -> 74, 118, 100, 76; result: 7411810076
	 *
	 * @param fullName
	 */
	export function initialsToNumbers(fullName: string): number {
		// const initials = fullName
		// 	.split(" ")
		// 	.map(name => name.trim()[0])
		// 	.join("")
		// 	.toUpperCase();

		const initialCodes = fullName
			.split(" ")
			.map((name) => name.trim().charCodeAt(0).toString(10))
			.join("");

		return Number.parseInt(initialCodes, 10);
	}

	/**
	 * Return client profile link
	 * @param hashId
	 * @param role
	 */
	export function getProfileLink(hashId: string, role: UserRole = UserRole.PATIENT): string {
		if (role === UserRole.PATIENT) return getClientTabMenuRoute(hashId, ContactTabPath.PROFILE);
		return getTherapistTabMenuRoute(hashId, ContactTabPath.PROFILE);
	}

	/**
	 * Return link to client data
	 * @param hashId
	 */
	export function getDataLink(hashId: string): string {
		return getClientTabMenuRoute(hashId, ContactTabPath.DATA);
	}

	/**
	 * Returns a `SEMANTIC_COLORS` corresponding to a given `PresenceAvailability`
	 * @param availability
	 */
	export function availabilityToColor(availability: PresenceAvailability): SEMANTIC_COLORS {
		return {
			[PresenceAvailability.Available]: SEMANTIC_COLORS.GREEN,
			[PresenceAvailability.DND]: SEMANTIC_COLORS.RED,
			[PresenceAvailability.Away]: SEMANTIC_COLORS.YELLOW,
			[PresenceAvailability.XA]: SEMANTIC_COLORS.GREY,
			[PresenceAvailability.Unavailable]: SEMANTIC_COLORS.GREY,
		}[availability];
	}

	/**
	 * Returns the first and the last name of a user if possible, otherwise returns `null`.
	 * @param userProfile
	 */
	export function getFirstAndLastNames(userProfile: UserData): Optional<PersonalName> {
		if (!userProfile) {
			return null;
		}
		const {firstName, lastName} = userProfile;
		return {firstName, lastName};
	}
}
